import { gql } from "graphql-tag";

export const GET_ORDERS = gql`
	fragment OrderFields on Order {
		id
		uuid
		name
		path
		monthly_record
		economic_order_id
		economic_order_number
		order_status_id
		customer {
			user_id
			user {
				name
			}
		}
		order_status {
			id
			status
		}
		order_totals
		order_shipment {
			id
			order_id
		}
		order_subscription_history {
			id
		}
		order_payment {
			payment_method_id
		}
		order_discount {
			order_id
			discount_id
			discount {
				code
			}
		}
		created_at
		deleted_at
	}
`;

export const ORDER_DETAIL = gql`
	fragment OrderDetailFields on Order {
		id
		name
		email
		economic_order_number
		economic_order_id
		order_status_id
		discount_price_with_tax
		discount_price_without_tax
		customer {
			id
			uuid
			phone_number
			user_id
			user {
				name
				email
				status
				roles
			}

			customer_group_id
			customer_group {
				name
			}
		}
		order_shipping {
			shipping_method_id
			shipping_method {
				name
			}
		}
		order_shipment {
			tracking_id
			carrier_name
			carrier_code
			url
			is_fulfilled
			is_notified
		}
		order_total {
			value
			title
			code
		}
		site {
			prices_with_tax
		}
		order_products {
			product_id
			price
			tax
			quantity
			total
			price_label
			price_label_without_tax
			product {
				id
				description {
					name
				}
				media {
					file_path
				}
			}
		}
		order_billing_address {
			name
			company
			phone_number
			address_1
			address_2
			city
			postal_code
			country_id
			country {
				name
			}
		}
		order_shipping_address {
			name
			company
			phone_number
			address_1
			address_2
			city
			postal_code
			country_id
			country {
				name
			}
		}
		order_payment {
			payment_method_id
			transaction_id
			payment_method {
				name
			}
		}
		order_status {
			status
		}
		order_notes {
			id
			note_type
			note
		}
		order_discount {
			order_id
			discount_id
			discount {
				code
			}
		}
		order_subscription_history {
			id
			current_order_id
			order_date
			order_subscription_id
			order_subscription {
				id
				tenure
			}
		}
		created_at
	}
`;

export const PAGINATION = gql`
	fragment Pagination on OrderPagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { GET_ORDERS, PAGINATION, ORDER_DETAIL };
